import React from "react"
import FLayout from "../../components/flayout";
import PricingWealth from "../../components/pricingWealth";

const Page = ({data}) => (
  <div className="v-100 d-flex flex-column">
    <FLayout showFooter={false} darkHeader={true} isFullPage={true} showSingleFooter={true} headerFixed={false} noSelBackground={true} seoFields={seoFields} showAnnouncement={false} loadTimesLandingPixel={true}>
      <PricingWealth data={data.allContentJson.edges[0].node.wealthPricingContent}/>
    </FLayout>
  </div>
)


const seoFields = {
  description: "Start managing your client investments in Stocks, Mutual Funds & other assets on web, iOS and Android devices.",
  // lang,
  // meta,
  // keywords,
  title: "Pricing for Wealth Professionals" 
}

export const query = graphql`
query wealthPricingPageQuery{
  allContentJson {
    edges {
      node {
        wealthPricingContent {
            banner {
              title
              subtitle
            }
            products {
              tier
              title
              for
              currency
              price
              perText
              gstText
              featuresTitle
              features {
                order
                title
              }
            }
        }
      }
    }
  }
  featuredReviews: allWordpressWpReview(
    filter: { acf: { featured: {eq: true}}}
  ){
    edges {
      node {
        id
        title
        acf {
          featured
        }
        excerpt
        review_cat
        content
        date
      }
    }
  }
  reviewCategs: allWordpressWpReviewCat {
    edges {
        node {
            wordpress_id
            slug
        }
    }
  }
}
`

export default Page