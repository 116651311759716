import React from "react"

import styles from "./styles.module.css";
import {isMobile,isIOS} from 'react-device-detect';
import PricingCard from "../pricingCard";
import Tooltip from "../../shared/Tooltip";
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';
import { PricingCalculator } from "../pricingCalculator";
import { navigate } from "gatsby";
import { getNumPortfoliosFromSliderMarks } from "../../common/gFunctions";
import { B2B2C_Tier } from "../../common/constants";
import GoogleReviewsSlider from "../googleReviewsSlider";

class PricingWealth extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isPricingCalculatorOpen: false,
            numPortfolios: 100
        }
    }

    handleUpdate = () => {

    }

    proceed = () => {

    }

    togglePricingCalculator = (isPricingCalculatorOpen) => {
        this.setState({isPricingCalculatorOpen});
    }

    onClickCTA = (product) => {
        navigate('/sign-up/wealth', {state: {tier: product.tier}});
    }

    changeNumPortfolios = (mark) => {
        this.setState({numPortfolios: getNumPortfoliosFromSliderMarks(mark)});
    }

    render() {
        const { data } = this.props;

        const { isPricingCalculatorOpen, numPortfolios } = this.state;

        var isMobileFinal = false, isIOSFinal = false;

        if (typeof window !== "undefined") {
          isMobileFinal = isMobile;
          isIOSFinal = isIOS;
        }

        console.log(data, 'dataPricing')

        return (
            <>
                <div className={[styles.pageContainer].join(' ')}>
                    <div className={styles.topContainer}>
                        <div className={[styles.title].join(' ')}>
                            {data.banner.title}
                        </div>
                        <div className={[styles.subtitle].join(' ')}>
                            {data.banner.subtitle}
                        </div>
                    </div>
                    <div className={[styles.contentContainer].join(" ")}>
                        <div className={styles.priceAndSliderContainer}>
                            {/* <div className={styles.sliderTitle}>
                                Number of client portfolios managed in MProfit
                                <label className={"mb-0"}>
                                    <span className="qcircle-large qcircle" id={'client-portfolios-tooltip'} data-toggle="tooltip" data-placement="right">?</span>
                                    <Tooltip text={[
                                        'MProfit lets you create client-wise portfolios.',
                                        'Generally, you can add all investments for one client into one portfolio. Therefore, you can assume that you will need to create a number of portfolios in MProfit that equals your client count.',
                                        'However, sometimes you might want to track certain assets separately. For example, a PMS. In this case, you might want to create a separate portfolio and thus have more than 1 portfolio per client.',
                                        'Please also note F&O data must be added into a separate F&O portfolio, which adds to your portfolio count.',
                                        'Our advice: Start with a portfolio count equal to the number of clients you manage, and if required - you can upgrade your plan at any time to add more portfolios!'
                                    ]} target={'client-portfolios-tooltip'}/>
                                </label>
                            </div> */}
                            {/* <div className={styles.sliderContainer}>
                                <Slider min={0} max={4} marks={marks} step={null} defaultValue={0}
                                    railStyle={{height: '8px', backgroundColor: '#0070e0', opacity: 0.08}}
                                    trackStyle={{height: '8px', backgroundColor: '#196ea4'}}
                                    dotStyle={(dotValue) => {
                                        if (dotValue == 4) {
                                            return {backgroundColor: 'rgba(0, 111, 223, 0.3)', border: 'none', width: 4, height: 4, left: '99%'}
                                        } else {
                                            return {backgroundColor: 'rgba(0, 111, 223, 0.3)', border: 'none', width: 4, height: 4};
                                        }
                                    }}
                                    activeDotStyle={{backgroundColor: 'transparent'}}
                                    handleStyle={{backgroundColor: '#196ea4', border: 'none', width: 28, height: 28, marginTop: -11, opacity: 1}}
                                    onAfterChange={this.changeNumPortfolios}
                                />
                            </div> */}
                            <div className={[styles.pricingCardsContainer].join(" ")}>
                                {
                                    data.products.map((product, index) => (
                                        <PricingCard product={product} isCollapsedInitial={isMobileFinal && index > 0} isLargeCard={true} isXLCard={true} onClickCTA={this.onClickCTA} key={index} isWealth={true}/>
                                        ))
                                    }
                            </div>
                            {/* <div className={styles.pricingCalculatorContainer}>
                                <div className={styles.pricingCalculatorTitle}>Pricing calculator</div>
                                <div className={styles.pricingCalculatorButton} onClick={()=>this.togglePricingCalculator(true)}>
                                    <span>Find out how much you need to pay annually for MProfit</span>
                                    <span><i className="fa fa-angle-right" aria-hidden="true"></i></span>
                                </div>
                            </div> */}
                        </div>
                    </div>
                    <GoogleReviewsSlider />
                </div>
                {/* {
                    isPricingCalculatorOpen &&
                    <PricingCalculator isOpen={true} togglePricingCalculator={this.togglePricingCalculator} isWealth={true}/>
                } */}
            </>
        );
    }
}

const marksStyle = { fontSize:14, marginTop: 8, fontFamily: 'Source Sans Pro' };
const marks = {
  0: {style:marksStyle,label:'100'},
  1: {style:marksStyle,label:'200'},
  2: {style:marksStyle,label:'500'},
  3: {style:marksStyle,label:'1000'},
  4: {style:marksStyle,label:'1000+'}
};

const getPrice = (b2b2cTier, numPortfolios) => {
    switch (b2b2cTier) {
        case B2B2C_Tier.ESSENTIAL:
            switch (numPortfolios) {
                case 100:
                    return 1000;
                case 200:
                    return 1250;
                case 500:
                    return 1500;
                case 1000:
                    return 2500;
                default:
                    return -1;
            }
            break;
        case B2B2C_Tier.ADVANCED:
            switch (numPortfolios) {
                case 100:
                    return 1500;
                case 200:
                    return 2000;
                case 500:
                    return 2500;
                case 1000:
                    return 4000;
                default:
                    return -1;
            }
            break;
        case B2B2C_Tier.PREMIUM:
            switch (numPortfolios) {
                case 100:
                    return 2500;
                case 200:
                    return 3000;
                case 500:
                    return 4000;
                case 1000:
                    return 6000;
                default:
                    return -1;
            }
            break;
    }
}

export default PricingWealth
